export const SearchData = {
    '/': {
        title: 'HOME',
        content: 'Created as a combined result of expertise in the delivery and telematics industries, Drivosity’s solutions increase productivity, inspire safer driving and reduce operating costs related to your delivery business. We empower businesses with the transparency...'
    },

    '/safety': {
        title: 'SAFETY',
        content: 'We believe that safety should be accessible to all. With DrivosityCORE, your drivers are empowered with the knowledge of their driving habits, bringing safe driving...'
    },

    '/productivity': {
        title: 'PRODUCTIVITY',
        content: 'Give your business a whole new edge. Maximize your operations with DrivosityEDGE, adding enhanced productivity, customer feedback, and driver engagement...'
    },

    '/financing': {
        title: 'FINANCING',
        content: 'At Drivosity we have partnered with our financing partner PEAC to make getting Drivosity even easier! With competitive rates for financing, the road to safer driving...'
    },

    '/about-us': {
        title: 'ABOUT US',
        content: 'We harness the power of technology to enhance the human experience. The values that drive us- for our customers and for their communities- focus on inspiring and responding to the needs of the individual in order to create a safer...'
    },

    '/for-business': {
        title: 'FOR BUSINESS',
        content: 'Increase overall business operations...'
    },

    '/for-drivers': {
        title: 'FOR DRIVERS',
        content: 'From empowering drivers with knowledge to providing new levels of safety and savings, drivers are at the heart of the Drivosity Solution...'
    },

    '/drivosity-and-hth': {
        title: 'DRIVOSITY AND HTH',
        content: 'A partnership built to drive increased sales and maximize operations...'
    },

    '/drivosity-certified': {
        title: 'DRIVOSITY CERTIFIED',
        content: 'Recognizing those who keep our communities safe...'
    },

    '/contact-us': {
        title: 'CONTACT US',
        content: 'If you have any questions about Drivosity or how we can support your business, please complete and submit our contact form...'
    },

    '/careers': {
        title: 'CAREERS',
        content: 'Since 2015, Drivosity has expanded as a company in both its team and its customers. To join a team of results-driven winners, apply here...'
    },

    '/careers-open?aW5kZXg=0': {
        title: 'BUSINESS ANALYST',
        content: 'Drivosity is looking for a Business Analyst...'
    },

    '/careers-open?aW5kZXg=1': {
        title: 'DATA SCIENTIST',
        content: 'Drivosity is looking for a Data Scientist...'
    },

    '/customer-stories': {
        title: 'CUSTOMER STORIES',
        content: 'You don’t just have to take our word for it. Listen to real results from our customers...'
    },

    '/request-demo': {
        title: 'REQUEST DEMO',
        content: ''
    },

    '/terms-of-use': {
        title: 'TERMS OF USE',
        content: ''
    },

    '/privacy-policy': {
        title: 'PRIVACY POLICY',
        content: ''
    },
}

import * as React from "react";

import DrvCover from "../Shared/DrvCover/DrvCover";
import DrvQuote from "../Shared/DrvQuote/DrvQuote";
import DrvContent from "../Shared/DrvContent/DrvContent";
import DrvSubFooter from "../Shared/DrvSubFooter/DrvSubFooter";
import DrvJobCard from "../Shared/DrvJobCard/DrvJobCard";
import { DrvButton } from "../Shared/DrvButton/DrvButton";

import {CareersData, JobsData} from './Careers.data';
import { JobContainer } from './Careers.styles';

import CareersImage from "../../images/Careers/Cover.jpg";
import Mensur from "../../images/Careers/Mensur.png";

const Careers = () => {
    return (
        <>
            <DrvCover
                title={CareersData[0].title}
                description={CareersData[0].content}
                color='#ffffff'
                image={CareersImage}
            />

            <div className="pt-12 pb-12">
                <DrvQuote
                    image={Mensur}
                    name={CareersData[1].data.name}
                    position={CareersData[1].data.position}
                    quote={CareersData[1].content}
                />
            </div>

            <div className="pt-12 pb-12">
                <DrvContent
                    type={['image', 'text']}
                    content={[
                        (
                            <>
                                <div className='drv-subtitle'>
                                    {CareersData[2].title}
                                </div>
                                <div className='drv-description mb-8'>
                                    {CareersData[2].content}
                                </div>
                            </>
                        )
                    ]}
                />
            </div>
            {
                JobsData.length > 0 && (
                    <JobContainer>
                        <div className="container mx-auto flex flex-wrap ">
                            <DrvJobCard data={JobsData}></DrvJobCard>
                        </div>
                    </JobContainer>
                )
            }
            <DrvSubFooter
                description={CareersData[3].content}
                button={
                    <DrvButton
                        to="/contact-us"
                        background="var(--primary-color)"
                        bordercolor="white"
                        className="w-fit">
                            Contact Us
                        </DrvButton>
                }
                color="#f5f5f7"
                background="var(--primary-color)"
            />
        </>
    )
}

export default Careers;

import styled from "styled-components";

export const DrvSquareCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: auto;
  padding: 30px;
  border-radius: 3px;
  margin-bottom: 10px;
  color: #212427;
  min-height: 150px;
  min-width: 430px;
  cursor: pointer;
  
  &:hover{
    background-color: #fff;
    color: #000;
  }
  ::after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-left: 3px solid var(--primary-color);
    transform: scale(1,0);
    transform-origin: 0 0;
    transition: transform 0.4s ;
  }
  &:hover::after{
    width: 100%;
    height: 100%;
    transform: scale(1,1);
  }
  &.active{
    color: #000;
    background-color: #fff;
  }
  &.active::after{
    width: 100%;
    height: 100%;
    transform: scale(1,1);
  }
`;

export const DrvSquareCardTitle = styled.div`
  font-weight: 600;
`;

export const DrvSquareCardBody = styled.div`
    font-size: 14px;
`;

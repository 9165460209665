import React from "react";

import DrvCover from "../../Shared/DrvCover/DrvCover";
import DrvQuote from "../../Shared/DrvQuote/DrvQuote";
import DrvContent from "../../Shared/DrvContent/DrvContent";
import DrvSubFooter from "../../Shared/DrvSubFooter/DrvSubFooter";
import {DrvButton} from "../../Shared/DrvButton/DrvButton";
import {AiOutlineArrowRight} from "react-icons/ai";
import {SafetyData} from "./Safety.data";


import Cover from "../../../images/Solutions/Safety/Cover.jpg";
import First from "../../../images/Solutions/Safety/First.svg";
import Second from "../../../images/Solutions/Safety/Second.svg";
import Third from "../../../images/Solutions/Safety/Third.svg";
import ScottPhoto from "../../../images/Solutions/Safety/ScottPhoto.png";

const Safety = () => {
    return (
        <>
            {/*CONTENT*/}
            <DrvCover
                title={SafetyData[0].title}
                subtitle={SafetyData[0].subtitle}
                description={SafetyData[0].content}
                color='#ffffff'
                demo={true}
                image={Cover}
            />

            {/*CONTENT*/}
            <div className="py-12">
                <DrvQuote
                    image={ScottPhoto}
                    name={SafetyData[1].data.name}
                    position={SafetyData[1].data.position}
                    quote={SafetyData[1].content}
                />
            </div>

            {/*CONTENT*/}
            <div className="py-14">
                <DrvContent
                    type={['text', 'image']}
                    content={[
                        (
                            <>
                                <div className='drv-subtitle'>
                                    {SafetyData[2].title}
                                </div>
                                <div className='drv-description'>
                                    {SafetyData[2].content}
                                    <br/><br/>
                                    <DrvButton to="/request-demo"
                                               className="w-fit"
                                               background="transparent"
                                               color="#545454"
                                               hoverbackground="var(--primary-color)"
                                               hoverborder="var(--primary-color)"
                                               hovercolor="#fff"
                                    >
                                        REQUEST DEMO
                                    </DrvButton>
                                </div>
                            </>
                        ),
                        (
                            <img src={First} alt="Speeding Marker mockup" />
                        )
                    ]}
                />
            </div>

            {/*CONTENT*/}
            <div className="py-14">
                <DrvContent
                    type={['image', 'text']}
                    content={[
                        (
                            <img src={Second} alt="Trip Card mockup" />

                        ),
                        (
                            <>
                                <div className='drv-subtitle'>
                                    {SafetyData[3].title}
                                </div>
                                <div className='drv-description'>
                                    {SafetyData[3].content}
                                </div>
                            </>
                        )
                    ]}
                />
            </div>

            {/*CONTENT*/}
            <div className="py-14">
                <DrvContent
                    type={['text', 'image']}
                    content={[
                        (
                            <>
                                <div className='drv-subtitle'>
                                    {SafetyData[4].title}
                                </div>
                                <div className='drv-description' style={{color: 'var(--text-color)'}}>
                                    {SafetyData[4].content}
                                </div>
                            </>
                        ),
                        (
                            <img src={Third} alt="Speed Limit updater mockup" />
                        )
                    ]}
                />
            </div>

            {/*CONTENT*/}
            <DrvSubFooter
                color="#f5f5f7"
                background="var(--primary-color)"
                description={SafetyData[5].content}
                button={
                    <DrvButton to="/for-business" background="var(--primary-color)" bordercolor="white" className="w-fit">
                        {SafetyData[5].title}
                        <AiOutlineArrowRight style={{fontSize: "17px"}} className="ml-3" />
                    </DrvButton>
                }
            />
        </>
    )
}

export default Safety;

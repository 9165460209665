import styled from "styled-components";

export const VideoContent = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  overflow:hidden;
  height: 780px;
  z-index: 1;
  
  & video {
    position: absolute;
    top: -120px;
    width: ${({width}) => width}px;
    height: ${({height}) => height}px;
    object-fit: cover;
  }
  
  & a {
    margin: 20px 0px 0px 50px;
  }
`

export const Cover = styled.img`
  position: absolute;
  width: 1250px;
  height: 890px;
  object-fit: cover;
`

export const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  color: white;
  border-radius: 0;
  margin: 0;
  padding: 5em 4em;
  background: rgb(35 35 35 / 48%); /* Translucent white */
  backdrop-filter: blur(10px); /* Apply blur */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  box-shadow: 0px 0px 10px 11px rgba(0, 0, 0, 0.1);
  text-align:left;
  justify-content: center;
  gap: 2em;
  align-items: flex-start;
  @media (min-width: 970px) {
    border-radius: 15px;
    justify-content: space-around;
    margin: 7em;
    width: 600px;
    height: 550px;
  }

  & .fade-in {
    opacity: 1;
    animation-name: fade-in;
    animation-timing-function: ease-in;
    animation-duration: 1.7s;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export const VideoText = styled.div`
  line-height: 44px;
  letter-spacing: 2px;
  font-weight: 700;
  font-size: 35px;
  color: #ffffff;
  white-space: nowrap;
  animation-name: fade-in-bottom;
  animation-duration: 1s;
  text-wrap:auto;
  text-wrap: auto;
  

  @media (min-width: 640px) {
    line-height: 57px;
    font-size: 45px;
  }
  
  & div {
    font-weight: 600;
  }

  @keyframes fade-in-bottom {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    
    to { 
      opacity: 1 
    }
  }
`
export const VideoDescription = styled.div`
  // line-height: 44px;
  // letter-spacing: 2px;
  font-weight: 500;
  font-size: 21px;
  color: #ffffff;
  white-space: nowrap;
  animation-name: fade-in-bottom;
  animation-duration: 1s;
  text-wrap: auto;
  
  & div {
    font-weight: 600;
  }

  @keyframes fade-in-bottom {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    
    to { 
      opacity: 1 
    }
  }
`

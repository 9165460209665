import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  position: relative; /* Needed for proper layering */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: ${({ color }) => color};
  min-height: 380px;
  padding: 100px 0;

  /* Layer for brightness filter */
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('${({ background }) => background}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // filter: brightness(${({ brightness }) => brightness || 1});
    filter: brightness(0.6);
    z-index: 0;
  }

  /* Content above the filtered background */
  & > .content {
    position: relative;
    z-index: 1; /* Ensure the content is above the background filter */
  }
`;

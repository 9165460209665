import React from "react";

import DrvCover from "../../Shared/DrvCover/DrvCover";
import DrvContent from "../../Shared/DrvContent/DrvContent";
import DrvSubFooter from "../../Shared/DrvSubFooter/DrvSubFooter";
import DrvVideoPlayer from "../../Shared/DrvVideoPlayer/DrvVideoPlayer";
import {DrvButton} from "../../Shared/DrvButton/DrvButton";
import {Text} from "./Productivity.style";
import {AiOutlineArrowRight} from "react-icons/ai";
import {ProductivityData} from "./Productivity.data";

import Cover from "../../../images/Solutions/Productivity/Cover.jpg";
import First from "../../../images/Solutions/Productivity/First.jpg";
import Second from "../../../images/Solutions/Productivity/Second.jpg";
import VideoPhoto from "../../../images/Solutions/Productivity/Video.jpg";
import TripReview from "../../../images/Solutions/Productivity/TripReview.png";
import IncidentReport from "../../../images/Solutions/Productivity/IncidentReport.png";
import Efficiency from "../../../images/Solutions/Productivity/Efficiency.png";
import DriveScore from "../../../images/Solutions/Productivity/DriveScore.png";

const Productivity = () => {
    return (
        <>
            {/*CONTENT*/}
            <DrvCover
                title={ProductivityData[0].title}
                subtitle={ProductivityData[0].subtitle}
                description={ProductivityData[0].content}
                color='#ffffff'
                demo={true}
                image={Cover}
            />

            {/*CONTENT*/}
            <div className="py-12">
                <DrvContent
                    type={['text', 'image']}
                    content={[
                        (
                            <>
                                <div className='drv-subtitle'>
                                    {ProductivityData[1].title}
                                </div>
                                <Text>
                                    {ProductivityData[1].content}

                                    <div style={{marginTop: '18px'}}>
                                        <DrvButton to="/request-demo"
                                                className="w-fit"
                                                background="transparent"
                                                color="#545454"
                                                hoverbackground="var(--primary-color)"
                                                hoverborder="var(--primary-color)"
                                                hovercolor="#fff"
                                        >
                                            REQUEST DEMO
                                        </DrvButton>
                                    </div>
                                </Text>
                            </>
                        ),
                        (
                            <DrvVideoPlayer height='500px'
                                            width='100%'
                                            image={VideoPhoto}
                                            url='https://www.youtube.com/embed/4XFje-_DlNY'
                            />
                        )
                    ]}
                />
            </div>

            {/*CONTENT*/}
            <div className="py-12" style={{background: "#efefef"}}>
                <DrvContent
                    type={['image', 'text']}
                    content={[
                        (
                            <img src={First} alt="To The Door Dashboard mockup" />
                        ),
                        (
                            <>
                                <div className='drv-subtitle'>
                                    {ProductivityData[7].title}
                                </div>
                                <Text>
                                    {ProductivityData[7].content}
                                </Text>
                                <div style={{marginTop: '18px'}}>
                                        <DrvButton to="/request-demo"
                                                className="w-fit"
                                                background="transparent"
                                                color="#545454"
                                                hoverbackground="var(--primary-color)"
                                                hoverborder="var(--primary-color)"
                                                hovercolor="#fff"
                                        >
                                            REQUEST DEMO
                                        </DrvButton>
                                    </div>
                            </>
                        )
                    ]}
                    color="#545454"
                    background="#efefef"
                />
            </div>

            {/*CONTENT*/}
            <div className="py-12" >
                <DrvContent
                    type={['text']}
                    content={[
                        (
                            <>
                                <div className='drv-subtitle'>
                                    {ProductivityData[2].title}
                                </div>
                                <Text>
                                    {ProductivityData[2].content}

                                    <div className="flex flex-wrap justify-between pt-14 gap-y-8 md:gap-y-4">
                                        <div className="flex flex-col basis-full sm:basis-1/2 md:basis-1/4">
                                            <div className="flex justify-center pb-8">
                                                <img src={TripReview} alt="Trip Review" />
                                            </div>
                                            {ProductivityData[3].content}
                                        </div>

                                        <div className="flex flex-col basis-full sm:basis-1/2 md:basis-1/4">
                                            <div className="flex justify-center pb-8">
                                                <img src={IncidentReport} alt="Incident Report" />
                                            </div>
                                            {ProductivityData[4].content}
                                        </div>

                                        <div className="flex flex-col basis-full sm:basis-1/2 md:basis-1/4">
                                            <div className="flex justify-center pb-8">
                                                <img src={Efficiency} alt="Efficiency" />
                                            </div>
                                            {ProductivityData[5].content}
                                       </div>

                                        <div className="flex flex-col basis-full sm:basis-1/2 md:basis-1/4">
                                            <div className="flex justify-center pb-8">
                                                <img src={DriveScore} alt="DriveScore" />
                                            </div>
                                            {ProductivityData[6].content}
                                        </div>
                                    </div>

                                    {ProductivityData[2].subcontent}
                                </Text>
                            </>
                        )
                    ]}
                    color="#545454"
                    background="#ffffff"
                />
            </div>

            

            {/*CONTENT*/}
            <div className="py-12" style={{background: "#efefef"}}>
                <DrvContent
                    type={['text', 'image']}
                    content={[
                        (
                            <>
                                <div className='drv-subtitle'>
                                    {ProductivityData[8].title}
                                </div>
                                <Text>
                                    {ProductivityData[8].content}
                                </Text>
                            </>
                        ),
                        (
                            <img src={Second} alt="Driver Feedbacks mockup" />
                        )
                    ]}
                    color="#545454"
                    background="#efefef"
                />
            </div>

            {/*CONTENT*/}
            <DrvSubFooter
                color="#f5f5f7"
                background="var(--primary-color)"
                description={ProductivityData[9].content}
                button={
                    <DrvButton to="/request-demo" background="var(--primary-color)" bordercolor="white" className="w-fit">
                        REQUEST DEMO
                        <AiOutlineArrowRight style={{fontSize: "17px"}} className="ml-3" />
                    </DrvButton>
                }
            />
        </>
    )
}

export default Productivity;

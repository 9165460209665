/**
 * DrvCover is shared Component which is used on common page of this Website.
 * Contains title of the page, description and specific image.
 *
 * To use this component you need to specify title, description, text-color and background-image.
 **/

import React from "react";
import { DrvButton } from "../DrvButton/DrvButton";

import {Container} from './DrvCover.styles';

const DrvCover = ({title, description, color, image, subtitle, demo, brightness }) => {
    return (
        <Container background={image} color={color} brightness={brightness}>
            <div className="content w-full flex justify-center">
                <div className="container mx-auto flex flex-col justify-center">
                    <div>
                        <div className="drv-title">
                        {title}
                        </div>
                        <div className="drv-description w-full md:w-1/2 flex flex-col gap-[15px]">
                        <div className="text-[18px] font-normal">
                            {subtitle}
                        </div>
                        {description}
                        {demo && (
                            <DrvButton
                            to="/request-demo"
                            className="w-fit"
                            background="transparent"
                            color="#ffffff"
                            hoverbackground="var(--primary-color)"
                            hoverborder="var(--primary-color)"
                            hovercolor="#fff"
                            >
                            REQUEST DEMO
                            </DrvButton>
                        )}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default DrvCover;

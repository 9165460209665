import React, { useState } from 'react';

import DrvCover from "../../Shared/DrvCover/DrvCover";
import { TextArea, ErrorMessage } from "./ContactUs.styles";

import DrvContent from "../../Shared/DrvContent/DrvContent";
import { DrvInput } from "../../Shared/DrvInput/DrvInput";
import { DrvButton } from '../../Shared/DrvButton/DrvButton'
import { AiOutlineArrowRight } from 'react-icons/ai';
import { GoLocation } from 'react-icons/go'
import { MdSmartphone , MdMailOutline } from 'react-icons/md';
import {ContactUsData} from "./ContactUs.data";

import ContactUsImage from "../../../images/OurCompany/ContactUs/Cover.jpeg";

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { request } from '../../../services/api';
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber} from 'react-phone-number-input';
const ContactUs = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [emailSend, setEmailSend] = useState(false);
    const [reqError, setReqError] = useState(false);
    const [reqErrorMsg, setReqErrorMsg] = useState('');

    const [inputs, setInputs] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });
    const defaultErrors = {
        name: null,
        email: null,
        phone: null,
        message: null,
    };
    const [errors, setErrors] = useState(defaultErrors);
    const inputChange = (e) =>{
        const {name, value} = e.target;
        setInputs({
            ...inputs,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: false
        })
    };
    const phoneNumberChange = (e) => {
        setInputs({
            ...inputs,
            phone: e,
        });
        setErrors({
            ...errors,
            phone: false,
        })
    }
    const validateField = (name, value) =>{
        switch (name){
            case "name":
                if(!value){
                    return true;
                }
                break;
            case "message":
                if(!value){
                    return true;
                }
                break;
            case "phone":
                if(!value){
                    return true;
                }
                if(!isValidPhoneNumber(value)){
                    return true;
                }
                break;
            case "email":
                if(!value){
                    return true;
                }
                else if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)){
                    return true;
                }
                break;
            default:
                break;
        }
    };
    const submitForm = async (e) =>{

        e.preventDefault();
        if(!executeRecaptcha){
            return
        }
        const result = await executeRecaptcha();
        let errorObj = {};
        setErrors(defaultErrors);
        let errorHandler = false;
        Object.entries(inputs).forEach(([item,val]) =>{
            if(typeof validateField(item, val)){
                if(validateField(item, val)){
                    errorObj = {...errorObj, [item]: validateField(item, val)};
                    errorHandler = true;
                }
            }
        })
        setErrors(errorObj);
        if(!errorHandler){
            let reqInputs = {
                captcha_token: result,
                first_name:inputs.name,
                email: inputs.email,
                phone_number: formatPhoneNumberIntl(inputs.phone),
                message: inputs.message
            }
            try {
                const response = await request('post','/email/contactus', reqInputs);
                if(response.success){
                    setEmailSend(true);
                }else{
                    setReqError(true);
                    setReqErrorMsg('Something went wrong, Please try again later')
                }
            } catch (err) {
                console.error(err)
                setReqError(true)
                if(typeof err.message === 'string'){
                    setReqErrorMsg(err.message)
                } else {
                    setReqErrorMsg('Something went wrong, Please try again later')
                }
            }
        }
    }

    return (
        <>
            
            <DrvCover
                title={ContactUsData[0].title}
                description={ContactUsData[0].content}
                color='#ffffff'
                image={ContactUsImage}
            />

            <div className="pt-12 pb-12" style={{background: "#f2f2f2"}}>
                <div className="flex justify-center">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">

                        <DrvContent
                            type={['text']}
                            content={[
                                (
                                    <>
                                        <div className="drv-title" style={{fontSize: "24px"}}>
                                            SALES
                                        </div>
                                        <div className='drv-description font-light'>
                                            sales@drivosity.com

                                            <br /><br />

                                            800-785-4548
                                        </div>
                                    </>
                                )
                            ]}
                            color="#545454"
                            background="#f2f2f2"
                        />

                        <DrvContent
                            type={['text']}
                            content={[
                                (
                                    <>
                                        <div className="drv-title" style={{fontSize: "24px"}}>
                                            GENERAL INQUIRIES
                                        </div>
                                        <div className='drv-description font-light'>
                                            info@drivosity.com

                                            <br /><br />

                                            407-986-0477
                                        </div>
                                    </>
                                )
                            ]}
                            color="#545454"
                            background="#f2f2f2"
                        />

                        <DrvContent
                            type={['text']}
                            content={[
                                (
                                    <>
                                        <div className="drv-title" style={{fontSize: "24px"}}>
                                            BILLING INQUIRIES
                                        </div>
                                        <div className='drv-description font-light'>
                                            accounting@drivosity.com
                                            <br /><br /><br />
                                        </div>
                                    </>
                                )
                            ]}
                            color="#545454"
                            background="#f2f2f2"
                        />

                        <DrvContent
                            type={['text']}
                            content={[
                                (
                                    <>
                                        <div className="drv-title" style={{fontSize: "24px"}}>
                                            TECH SUPPORT
                                        </div>
                                        <div className='drv-description font-light'>
                                            support@drivosity.com

                                            <br /><br />

                                            800-785-4548 Option 3
                                        </div>
                                    </>
                                )
                            ]}
                            color="#545454"
                            background="#f2f2f2"
                        />

                    </div>
                </div>
            </div>


            <div className="pt-12 pb-12">
                {
                    !emailSend && (
                        <>
                        <DrvContent
                            type={['text']}
                            content={[
                                (
                                    <>
                                        <div className="drv-title">
                                            CONTACT US
                                        </div>
                                        <div className='drv-description font-light'>
    
                                        </div>
                                    </>
                                )
                            ]}
                            color="#545454"
                            background="#ffffff"
                        />
                        <DrvContent
                            type={['text']}
                            content={[
                                (
                                    <>
                                        <div className="flex flex-wrap">
                                            <div className="flex basis-full md:basis-1/2 flex-col pr-0 md:pr-2">
                                                <DrvInput className={errors.name ? "error" : ""} onChange={inputChange} name="name" type="text" placeholder="Full Name *" />
                                                <DrvInput className={errors.email ? "error" : ""} onChange={inputChange} name="email" type="email" placeholder="E-mail *" />
                                                <PhoneInput 
                                                    className={errors.phone ? "error" : ""} 
                                                    international
                                                    defaultCountry="US"
                                                    limitMaxLength={true}
                                                    value={inputs.phone}
                                                    onChange={phoneNumberChange}
                                                    placeholder='Phone Number *'
                                                />
                                            </div>
                                            <div className="flex basis-full md:basis-1/2 flex-col pr-0 md:pr-2 items-end">
                                                <TextArea className={`${errors.message ? "error" : ""}`} onChange={inputChange} name="message" placeholder="Message *"></TextArea>
                                                <DrvButton className="mt-5 justify-center"
                                                    to="#"
                                                    onClick={submitForm}
                                                    background="transparent"
                                                    color="#000"
                                                    hoverbackground="var(--primary-color)"
                                                    hovercolor="#fff"
                                                >SUBMIT
                                                <AiOutlineArrowRight style={{fontSize: "15px"}} className="ml-2" />
                                                </DrvButton>
                                            </div>
                                            <div className="flex basis-full">
                                                {
                                                    (errors.name || errors.email || errors.message) && (
                                                        <ErrorMessage>
                                                            Validation errors occurred. Please confirm the fields and submit it again.
                                                        </ErrorMessage>
                                                    )
                                                }
                                                {
                                                    (!errors.name && !errors.email && !errors.message) && errors.phone && (
                                                        <ErrorMessage>
                                                            Phone number filed can not be empty or phone number is not valid. Please enter a valid phone number.
                                                        </ErrorMessage>
                                                    )
                                                }
                                                {
                                                    (reqError) && (
                                                        <ErrorMessage>
                                                            {reqErrorMsg}
                                                        </ErrorMessage>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            ]}
                            background="#f2f2f2"
                        />
                        </> 
                    )
                }
                {
                    emailSend &&
                    <DrvContent
                        type={['text']}
                        content={[
                            (
                                <>
                                    <div className="drv-title">
                                        Email Sent Successfully
                                    </div>
                                </>
                            )
                        ]}
                        background="#f2f2f2"
                    />
                }
            </div>
            <div className="text-white" style={{backgroundColor: '#0e6faa'}}>
                <div className="container m-auto flex flex-wrap pt-[60px] pb-[60px] pl-0 pr-0 text-sm">
                    <div className="flex flex-col basis-full md:basis-1/2 lg:basis-1/2   pr-[10px] md:pt-[40px]">
                        <div className="font-semibold text-base">
                            UNITED STATES HEADQUARTERS
                        </div>
                        <br />
                        <div className="flex items-center">
                            <GoLocation className="mr-[10px]"></GoLocation>290 Citrus Tower Blvd.Suite 236, Clermont, FL 34711
                        </div>
                        <br />

                        <div className="flex items-center">
                            <MdSmartphone className="mr-[10px]"></MdSmartphone>
                            +8OO-785-4548
                        </div>
                        <br />
                        <div className="flex items-center">
                            <MdMailOutline className="mr-[10px]"></MdMailOutline>
                            info@drivosity.com
                        </div>
                    </div>
                    <div className="flex flex-col basis-full md:basis-1/2 lg:basis-1/2  pl-[10px] pr-[10px] md:pt-[40px] ">
                        <div className="font-semibold text-base">
                            UNITED STATES PRODUCTION FACILITY
                        </div>
                        <br />
                        <div className="flex items-center">
                            <GoLocation className="mr-[10px]"></GoLocation>
                            711 Jackson Ave, Winter Park, FL 32789
                        </div>
                        <br />
                        <div className="flex items-center">
                            <MdSmartphone className="mr-[10px]"></MdSmartphone>
                            +4O7-986-0477
                        </div>
                        <br />
                        <div className="flex items-center">
                            <MdMailOutline className="mr-[10px]"></MdMailOutline>
                            support@drivosity.com
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUs;

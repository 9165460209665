import React from "react";

import DrvCover from "../../Shared/DrvCover/DrvCover";
import DrvContent from "../../Shared/DrvContent/DrvContent";
import DrvSubFooter from "../../Shared/DrvSubFooter/DrvSubFooter";
import {DrvButton} from "../../Shared/DrvButton/DrvButton";
import {DrivosityHTHData} from "./DrivosityHTH.data";

import Cover from "../../../images/OurCompany/DrivosityHTH/Cover.jpg";
import First from "../../../images/OurCompany/DrivosityHTH/First.jpg"

const DrivosityHTH = () => {
    return (
        <>
            {/*CONTENT*/}
            <DrvCover
                title={DrivosityHTHData[0].title}
                description={DrivosityHTHData[0].content}
                color='#ffffff'
                image={Cover}
            />

            {/*CONTENT*/}
            <div className="pt-12">
                <DrvContent
                    type={['text', 'image']}
                    content={[
                        (
                            <>
                                <div className='drv-subtitle'>
                                    {DrivosityHTHData[1].title}
                                </div>

                                <div className='drv-description'>
                                    {DrivosityHTHData[1].content}
                                </div>
                            </>
                        ),
                        (
                            <img src={First} alt="Driver using Drivoisty application" />
                        )
                    ]}
                />
            </div>

            {/*CONTENT*/}
            <div className='pb-12 flex flex-col justify-center items-center drv-description' style={{paddingLeft: '45px', paddingRight: '45px'}}>
                <div className='mb-7 font-medium'>
                    {DrivosityHTHData[2].content}
                </div>
                <DrvButton to="https://www.carsigns.com"
                           background="transparent"
                           color="#545454"
                           hoverbackground="var(--primary-color)"
                           hoverborder="var(--primary-color)"
                           hovercolor="#fff"
                           className="w-fit"
                >
                    CLICK HERE
                </DrvButton>
            </div>

            {/*CONTENT*/}
            <DrvSubFooter
                description={DrivosityHTHData[3].content}
                button={
                    <DrvButton to="/request-demo" background="var(--primary-color)" bordercolor="white" className="w-fit">REQUEST A DEMO</DrvButton>
                }
                color="#f5f5f7"
                background="var(--primary-color)"
            />

        </>
    )
}

export default DrivosityHTH;

import React from "react";

import DrvCover from "../../Shared/DrvCover/DrvCover";
import DrvContent from "../../Shared/DrvContent/DrvContent";
import DrvSubFooter from "../../Shared/DrvSubFooter/DrvSubFooter";
import DrvVideoPlayer from "../../Shared/DrvVideoPlayer/DrvVideoPlayer";
import DrvTabCollapse from "../../Shared/DrvTabCollapse/DrvTabCollapse";
import {AiOutlineArrowRight} from "react-icons/ai";
import {SubFooterContent, SubFooterTitle} from "../../Home/Home.styles";
import {DrvButton} from "../../Shared/DrvButton/DrvButton";
import {ForBusinessData, ForBusinessBenefitsCollapse, ForBusinessComponentsCollapse} from "./ForBusiness.data";

import Cover from "../../../images/OurCompany/ForBusiness/Cover.jpg";
import First from "../../../images/OurCompany/ForBusiness/First.jpg";
import VideoPhoto from "../../../images/OurCompany/ForBusiness/Video.jpg";

const ForBusiness = () => {
    return (
        <>
            {/*CONTENT*/}
            <DrvCover
                title={ForBusinessData[0].title}
                description={ForBusinessData[0].content}
                color='#ffffff'
                image={Cover}
            />

            {/*CONTENT*/}
            <div className="py-12">
                <DrvContent
                    type={['text', 'image']}
                    content={[
                        (
                            <>
                                <div className='drv-subtitle'>
                                    {ForBusinessData[1].title}
                                </div>
                                <div className='drv-description'>
                                    {ForBusinessData[1].content}
                                </div>
                            </>
                        ),
                        (
                            <DrvVideoPlayer height='500px' width='100%' image={VideoPhoto} url={"https://www.youtube.com/embed/LaXOFMRT4mw"} />
                        )
                    ]}
                />
            </div>

            {/*CONTENT*/}
            <div className="py-12" style={{background: "#efefef"}}>
                <DrvContent
                    type={['text']}
                    content={[
                        (
                            <>
                                <div className='drv-subtitle'>
                                    {ForBusinessData[2].title}
                                </div>
                                <div className='drv-description'>
                                    <div className='mb-7'>
                                        {ForBusinessData[2].content}
                                    </div>
                                    {ForBusinessData[3].content}
                                </div>
                            </>
                        )
                    ]}
                />
            </div>

            {/*CONTENT*/}
            <div className='container mx-auto py-12'>
                <div className='drv-subtitle'>ADDITIONAL BENEFITS</div>
                <DrvTabCollapse data={ForBusinessBenefitsCollapse}/>
            </div>

            {/*CONTENT*/}
            <div className='py-12'>
                <DrvContent
                    type={['image', 'text']}
                    content={[
                        (
                            <img src={First} alt="Driver loving Drivosity application" />
                        ),
                        (
                            <>
                                <div className='drv-subtitle'>
                                    {ForBusinessData[4].title}
                                </div>

                                <div className='drv-description'>
                                    <div className='mb-7'>
                                        {ForBusinessData[4].content}
                                    </div>
                                    <DrvTabCollapse data={ForBusinessComponentsCollapse}/>
                                </div>
                            </>
                        )
                    ]}
                    color="#545454"
                    background="#ffffff"
                />
            </div>

            {/*CONTENT*/}
            <DrvSubFooter
                color="#f5f5f7"
                background="var(--primary-color)"
                description={
                    <div className='flex flex-col'>
                        <SubFooterTitle>{ForBusinessData[5].title}</SubFooterTitle>
                        <SubFooterContent>{ForBusinessData[5].content}</SubFooterContent>
                    </div>
                }
                button={
                    <DrvButton to="/contact-us" background="var(--primary-color)" bordercolor="white" className="w-fit">
                        GET STARTED TODAY
                        <AiOutlineArrowRight style={{fontSize: "17px"}} className="ml-3" />
                    </DrvButton>
                }
            />
        </>
    )
}

export default ForBusiness;

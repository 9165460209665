import styled from "styled-components"

export const Container = styled.div`
  color: #595959;
`

export const Content = styled.div`
  min-height: 300px;
  background-color: #f5f5f7;
  border-radius: 3px;
  
  img {
    height: 335px;
  }

  @media (max-width: 969px) {
    height: max-content;
    flex-flow: wrap;
    padding: 10px 30px;
    
    img {
      height: auto; 
    }
  }
`

export const Description = styled.div`
  height: fit-content;
  line-height: 26px;
  letter-spacing: 0px;
  font-weight: 300;
  font-size: 16px;
`

export const Author = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #404040;
  padding-top: 20px
`

export const Position = styled.div`
  font-weight: 400;
  color:rgb(86, 86, 86);
  padding-top: 10px
`


